<template>
  <div>
    <b-modal
      id="assign-staff-manager"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AssignManagerToFiliale')"
      no-close-on-backdrop
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-2"
          :label="$t('Manager')"
          label-for="input-2"
        >
          <vue-select
            v-model="manager"
            required
            :options="getFreeManagers"
            label="userFullName"
            :value="userId"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <pre />
        <div style="width: 110%">
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 5px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="submit"
          >
            {{ $t("Submit") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  props: ['storeId'],
  data() {
    return {
      isTeamLeader: false,
      isCoTeamLeader: false,
      userId: null,
      teamLeader: null,
      manager: null,
      isPhoneNumberValid: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      form: {
        storeId: null,
        employeeUserId: null,
        isManager: true,
        isTeamLeader: false,
        isCoTeamLeader: false,
      },
    };
  },
  computed: {
    ...mapGetters(['getFreeManagers']),

  },

  watch: {
    'manager.userId': {
      handler(value) {
        this.form.employeeUserId = value;
      },
      immediate: true,
    },
    storeId: {
      handler(value) {
        this.form.storeId = value;
      },
      immediate: true,
    },
  },
  mounted() {
    this.form.storeId = this.storeId
  },
  methods: {
    ...mapActions(['loadUsersFullNamesById']),
    submit() {
      this.$emit('assignManager', this.form);
      this.$refs.modal.hide();
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
      this.form.teamLeaderUserId = '';
      this.manager = '';
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
