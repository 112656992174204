<template>
  <div>
    <b-sidebar
      id="edit-filiale-toggle"
      ref="modal"
      header-class="headerModali"
      centered
      right
      shadow
      :title="$t('EditNewFiliale')"
      hide-footer
      width="50%"
      @close="onCancel"
    >
      <template #title>
        <strong style="color: #242f6e;">{{ `${$t('EditNewFiliale')}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #242f6e;"
          class="fa fa-close"
          @click="onCancel"
        />
      </template>
      <b-form style="padding-left: 10px;">
        <b-tabs
          v-model="tabIndex"
        >
          <b-tab
            :title="$t('FilialeInformation')"
          >
            <template #title>
              <strong style="color: #0071AE">{{ $t("FilialeInformation") }}</strong>
            </template>
            <div
              class="d-flex"
            >
              <b-form-group
                id="input-group-1"
                :label="$t('FilialeName')"
                label-for="input-1"
                style="width: 47%; margin-right: 25px; margin-top: 10px;"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.filiale.name.$model"
                  :state="validateState('name')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                :label="$t('SelectCanton')"
                label-for="input-2"
                style="width: 47%; margin-right: 25px; margin-top: 10px;"
              >
                <vue-select
                  v-model="cantons"
                  required
                  :options="getCantons"
                  label="name"
                  :value="canton"
                  :placeholder="$t('Select')"
                  aria-describedby="input-1-live-feedback"
                />
                <span
                  v-show="formSubmitted && !$v.cantons.required"
                  style="color: red"
                >{{ $t('PleaseSelectCanton') }}</span>
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('GlobalNumber')"
                label-for="input-1"
                style="width: 47%; margin-right: 25px; margin-top: 10px;"
              >
                <b-form-input
                  id="input-3"
                  v-model="$v.filiale.gln.$model"
                  :state="validateState('gln')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('Email')"
                label-for="input-1"
                style="width: 47%; margin-top: 10px;"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.filiale.email.$model"
                  :state="validateState('email')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_email_format")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <b-form-group
              id="input-group-6"
              :label="`${$t('PhoneNumber')}:`"
              label-for="input-9"
              style="width: 47%; margin-top: 10px;"
            >
              <vue-phone-number-input
                id="input-6"
                v-model="filiale.phone"
                default-country-code="CH"
                :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                :preferred-countries="['AL', 'CH', 'DE']"
                :state="validateState('phone')"
                @update="getFormattedNumber"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
          </b-tab>
          <pre />
          <b-tab
            :title="$t('AddressInformations')"
          >
            <template #title>
              <strong style="color: #0071AE">{{ $t("AddressInformations") }}</strong>
            </template>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('Street')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.filiale.street.$model"
                  :state="validateState('street')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('EmployeeHouseNumber')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.filiale.houseNumber.$model"
                  :state="validateState('houseNumber')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('EmployeeDoorNumber')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.filiale.doorNumber.$model"
                  :state="validateState('doorNumber')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_contains_numbers")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('EmployeePostalC')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.filiale.postalCode.$model"
                  :state="validateState('postalCode')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('Stadt')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.filiale.city.$model"
                  :state="validateState('city')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('Land')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.filiale.country.$model"
                  :state="validateState('country')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
          </b-tab>
          <b-tab
            :title="$t('ShippingInformation')"
          >
            <template #title>
              <strong style="color: #0071AE">{{ $t("ShippingInformation") }}</strong>
            </template>
            <div class="d-flex">
              <b-form-group
                id="input-group-2"
                :label="$t('Manager')"
                label-for="input-2"
              >
                <vue-select
                  v-model="manager"
                  required
                  :options="getFreeManagers"
                  label="userFullName"
                  :value="userId"
                  placeholder="Select Manager"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
              <b-form-group
                id="input-group-2"
                :label="$t('TeamLeader')"
                label-for="input-2"
              >
                <vue-select
                  v-model="teamLeader"
                  :options="getfreeTeamLeaders"
                  label="userFullName"
                  :value="userId"
                  placeholder="Select TeamLeader"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </div>
            <b-form-group
              id="input-group-2"
              :label="$t('Co-TeamLeader')"
              label-for="input-2"
            >
              <vue-select
                v-model="coTeamLeader"
                label="userFullName"
                :options="getfreeCoTeamLeaders"
                :value="userId"
                placeholder="Select Co-TeamLeader"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </b-tab>
          <pre />
        <!-- <div>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t("Submit") }}
          </b-button>
        </div> -->
        </b-tabs>
      </b-form>
      <div style="margin-top: 15px;padding-left: 10px;">
        <b-button
          v-show="tabIndex ==2"
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          v-show="tabIndex ==0 || tabIndex ==1"
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="tabIndex++"
        >
          {{ $t("EmployeeNext") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
  alpha,
  minValue,
  numeric,
  // alphaNum,
  email,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  components: {},
  mixins: [validationMixin],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    editFiliale: {
      type: Object,
    },
  },
  data() {
    return {
      canton: null,
      userId: null,
      storeId: null,
      teamLeader: null,
      manager: null,
      tabIndex: 0,
      formSubmitted: false,
      coTeamLeader: null,
      isPhoneNumberValid: null,
      cantons: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      filiale: {
        teamLeaderId: null,
        coTeamLeaderId: null,
        managerId: null,
        countryState: null,
        countryStateId: '',
      },
    };
  },
  validations: {
    filiale: {
      name: {
        required,
        minLength: minLength(3),
        // alphaNum,
      },
      gln: {
        required,
        minLength: minLength(3),
        // alphaNum,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
      street: {
        required,
        minLength: minLength(3),
        // alpha,
      },
      postalCode: {
        required,
        minLength: minLength(3),
        // alphaNum,
      },
      doorNumber: {
        required,
        minLength: minValue(1),
        numeric,
      },
      houseNumber: {
        required,
        minLength: minLength(1),
      },
      country: {
        required,
        minLength: minLength(3),
        alpha,
      },
      city: {
        required,
        minLength: minLength(3),
        alpha,
      },
      // canton: {
      //   required,
      // },
    },
    cantons: {
      required,
    },
  },
  computed: {
    ...mapGetters(['getCantons', 'getFilialebyId', 'getfreeCoTeamLeaders', 'getfreeTeamLeaders', 'getFreeManagers']),
    filialeById() {
      return this.$store.getters.getFilialebyId;
    },
  },
  watch: {
    'cantons.name': {
      handler(value) {
        this.filiale.countryState = value;
      },
      immediate: true,
    },
    'teamLeader.userId': {
      handler(value) {
        this.filiale.teamLeaderId = value;
      },
      immediate: true,
    },
    'manager.userId': {
      handler(value) {
        this.filiale.managerId = value;
      },
      immediate: true,
    },
    'coTeamLeader.userId': {
      handler(value) {
        this.filiale.coTeamLeaderId = value;
      },
      immediate: true,
    },

    coTeamLeader: {
      handler(value) {
        if (value == null) {
          this.filiale.coTeamLeaderId = null;
        }
      },
      immediate: true,
    },
    teamLeader: {
      handler(value) {
        if (value == null) {
          this.filiale.teamLeaderId = null;
        }
      },
      immediate: true,
    },
    manager: {
      handler(value) {
        if (value == null) {
          this.filiale.managerId = null;
        }
      },
      immediate: true,
    },
    filialeById: {
      immediate: true, // update the data property immediately on component mount
      handler(newVal) {
        // this.storeId = newVal.storeId;
        this.filiale = {
          storeId: newVal.storeId,
          name: newVal.name,
          gln: newVal.gln,
          phone: newVal.phone,
          email: newVal.email,
          street: newVal.street,
          houseNumber: newVal.houseNumber,
          doorNumber: newVal.doorNumber,
          postalCode: newVal.postalCode,
          city: newVal.city,
          country: newVal.country,
          countryCode: newVal.countryCode,
          countryState: newVal.countryState,
          countryStateId: newVal.countryStateId,
          countryId: '47bcbbb6-6d82-4682-65c5-08db2c4e9562',
          // teamLeaderName: newVal.teamLeaderName,
          // coTeamLeaderName: newVal.coTeamLeaderName,
          // canton: newVal.canton,
          teamLeaderId: newVal.teamLeaderId,
          coTeamLeaderId: newVal.coTeamLeaderId,
          managerId: newVal.managerId,
        }
        this.manager = {
          userId: newVal.managerId,
          userFullName: newVal.managerName,
        }
        this.teamLeader = {
          userId: newVal.teamLeaderId,
          userFullName: newVal.teamLeaderName,
        }
        this.coTeamLeader = {
          userId: newVal.coTeamLeaderId,
          userFullName: newVal.coTeamLeaderName,
        }
        this.cantons = {
          name: newVal.countryState,
          countryStateId: newVal.countryStateId,
        }
      },
    },
  },

  mounted() {
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.filiale[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.formSubmitted = true
      this.$v.filiale.$touch();
      if (this.$v.filiale.$anyError) {
        return;
      }
      this.$v.cantons.$touch();
      if (this.$v.cantons.$anyError) {
        return;
      }
      this.$emit('edit', this.filiale);
      // this.$emit('id', this.storeId);
      this.$refs.modal.hide();
      // this.onReset()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      this.filiale.name = '';
      this.filiale.gln = '';
      this.filiale.phone = '';
      this.filiale.email = '';
      this.filiale.street = '';
      this.filiale.street = '';
      this.filiale.houseNumber = '';
      this.filiale.doorNumber = '';
      this.filiale.postalCode = '';
      this.filiale.country = '';
      this.filiale.city = '';
      this.filiale.canton = '';
      this.cantons = '';
      this.formSubmitted = false
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.filiale.phone = n.nationalNumber
        this.filiale.countryCode = n.countryCallingCode
      } else {
        this.filiale.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
  },
};
</script>

<style lang="scss" scoped>
// form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }

.d-flex{
  display: flex;
}

#input-group-1, #input-group-2{
  width: 47%;
  margin-right: 25px;
  margin-top: 10px;
}
// .form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form2 {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
