<template>
  <div style="padding: 50px 15px 15px 0px">
    <div>
      <div
        v-for="obj in filteredObjects"
        :key="obj.id"
      >
        <h1 style="text-align: left;">
          {{ obj.storeName }} {{ $t("Filiale") }}
        </h1>
        <div class="all">
          <div style="display: flex;">
            <div class="vacation">
              <b-row class="row">
                <b-col
                  class="col-lg-4 col-md-6 col-sm-6 col-6 one box"
                  @click="redirectToUser"
                >
                  <img
                    style="height: 88px"
                    src="../assets/images/icons for DS Logistik SPIM.-14.svg"
                    alt=""
                  >
                  <div class="text">
                    <p style="font-weight: bold">
                      {{ $t("No.Employee") }}
                    </p>
                    <p style="font-size: 25px">
                      {{ getStoreMainboardOverview.employees }}
                    </p>
                  </div>
                </b-col>
                <b-col
                  class="col-lg-4 col-md-6 col-sm-6 col-6 one box"
                  @click="redirectToTruck"
                >
                  <img
                    style="height: 88px"
                    src="../assets/images/icons for DS Logistik SPIM.-10.svg"
                    alt=""
                  >
                  <div class="text">
                    <p style="font-weight: bold">
                      {{ $t("ActiveTrucks") }}
                    </p>
                    <p style="font-size: 25px">
                      {{ getStoreMainboardOverview.activeTrucks }}
                    </p>
                  </div>
                </b-col>
                <b-col
                  class="col-lg-4 col-md-6 col-sm-6 col-6 one box"
                  @click="redirectToTruck"
                >
                  <img
                    style="height: 88px"
                    src="../assets/images/icons for DS Logistik SPIM.-11.svg"
                    alt=""
                  >
                  <div class="text">
                    <p style="font-weight: bold">
                      {{ $t("InactiveTrucks") }}
                    </p>
                    <p style="font-size: 25px">
                      {{ getStoreMainboardOverview.inActiveTrucks }}
                    </p>
                  </div>
                </b-col>
                <b-col
                  class="col-lg-4 col-md-6 col-sm-6 col-6 one box"
                  @click="redirectToRoutes"
                >
                  <img
                    style="height: 88px"
                    src="../assets/images/icons for DS Logistik SPIM.-13.svg"
                    alt=""
                  >
                  <div class="text">
                    <p style="font-weight: bold">
                      {{ $t("Avg.Routes") }}
                    </p>
                    <p style="font-size: 25px">
                      {{ getStoreMainboardOverview.averageRoutesPerDay }}
                    </p>
                  </div>
                </b-col>
                <b-col
                  class="col-lg-4 col-md-6 col-sm-6 col-6 one box"
                  @click="redirectToTasks"
                >
                  <img
                    style="height: 88px"
                    src="../assets/images/icons for DS Logistik SPIM.-09.svg"
                    alt=""
                  >
                  <div class="text">
                    <p style="font-weight: bold">
                      {{ $t("EstimatedEarnings") }}
                    </p>
                    <p style="font-size: 25px">
                      {{ getStoreMainboardOverview.employees * 700 }}
                    </p>
                  </div>
                </b-col>
                <b-col class="col-lg-4 col-md-6 col-sm-6 col-6 one box">
                  <img
                    style="height: 88px"
                    src="../assets/images/icons for DS Logistik SPIM.-12.svg"
                    alt=""
                  >
                  <div class="text">
                    <p style="font-weight: bold">
                      {{ $t("Location") }}
                    </p>
                    <p>
                      {{ getStoreMainboardOverview.location }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="statics">
              <b-container>
                <b-row>
                  <b-col>
                    <carousel
                      class="custom-carousel"
                      :per-page="1"
                    >
                      <slide>
                        <h3
                          style="text-align: left; color: white;"
                        >
                          {{ $t('LeaveRequest') }}
                        </h3>
                        <div
                          class="mt-2"
                          style="display: flex; justify-content: end; margin-right: 45px; padding-top: 10px"
                        >
                          <b-button
                            type="button"
                            variant="none"
                            :class="{ active: isActive }"
                            style="background-color: #fff8f8; color: #242f6e; margin-right: 10px"
                            @click="
                              time1(10);
                              toggleActive();
                            "
                          >
                            10 {{ $t("Days") }}
                          </b-button>
                          <b-button
                            v-model="time2"
                            type="button"
                            variant=""
                            style="background-color: #fff8f8; color: #242f6e; margin-right: 10px"
                            @click="time1(20)"
                          >
                            20 {{ $t("Days") }}
                          </b-button>
                          <b-button
                            v-model="time3"
                            type="button"
                            variant=""
                            style="background-color: #fff8f8; margin-right: 10px; color: #242f6e"
                            @click="time1(30)"
                          >
                            30 {{ $t("Days") }}
                          </b-button>
                          <b-button
                            v-model="time3"
                            type="button"
                            variant=""
                            style="background-color: #fff8f8; color: #242f6e"
                            @click="time1(50)"
                          >
                            50 {{ $t("Days") }}
                          </b-button>
                        </div>
                        <table
                          class="team_table"
                          style="
                  width: 94% !important;
                  box-shadow: 0px 7px 20px rgb(40 41 61 / 8%);
                  margin-top: 15px;
                  margin-left: 30px;
                "
                        >
                          <thead>
                            <tr>
                              <th style="padding: 10px; border-radius: 10px 0px 0px 0px; ">
                                {{ $t("Name") }}
                              </th>
                              <th style="padding: 10px ;">
                                {{ $t("DateFrom-to") }}
                              </th>
                              <th style="padding: 10px; border-radius: 0 10px 0 0">

                                {{ $t("VacationType") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="getUpComingVacations !== null">
                            <tr
                              v-for="item in getUpComingVacations"
                              :key="item.employeeUserId"
                              style="
                    border-bottom: 1px solid #dcdfe6;
                    color: #606266;
                    cursor: pointer;
                    background-color: white;"
                              @click="redirectToVacation()"
                            >
                              <td style="padding: 10px; font-size: 12px;">
                                {{ item.fullName }}
                              </td>
                              <td style="padding: 10px; font-size: 12px;">
                                {{ modifiedDate(item.startDate) }} - {{ modifiedDate(item.endDate) }}
                              </td>
                              <td style="padding: 10px; color: red">

                                {{ item.vacationType }}
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-if="!getUpComingVacations">
                            <tr>
                              <td colspan="3">
                                No upcoming vacations.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </slide>
                      <slide class="p-2">
                        <div
                          style="text-align: left;"
                        >
                          <h3
                            style="text-align: left; color: white;"
                          >
                            {{ $t('Employees') }}
                          </h3>
                          <div class="first-statics">
                            <table
                              class="team_table"
                              style="width: 100%; box-shadow: 0px 7px 20px rgb(40 41 61 / 8%);"
                            >
                              <thead>
                                <tr>
                                  <th style="padding: 10px; border-radius: 10px 0px 0px 0px;">
                                    {{ $t("Name") }}
                                  </th>
                                  <th style="padding: 10px; border-radius: 0px 10px 0px 0px; text-align: left;">
                                    {{ $t("Type") }}
                                  </th>
                                </tr>
                              </thead>
                            </table>
                            <div class="table-body-container">
                              <table
                                class="team_table"
                                style="width: 100%;"
                              >
                                <tbody v-if="getRegularEmployeesOverview !== null">
                                  <tr
                                    v-for="item in getRegularEmployeesOverview"
                                    :key="item.userId"
                                    style="
                                    border-bottom: 1px solid #dcdfe6;
                                    color: #606266;
                                    cursor: pointer;
                                    background-color: white;
                                    "
                                    @click="matProps(name)"
                                  >
                                    <td
                                      style="padding: 10px; font-size: 12px;"
                                      :style="item.employeeType == 'Internal' ? 'background-color: #8FBC8F': 'background-color: #FFD700 '"
                                    >
                                      <p style="font-weight: bold;">
                                        {{ item.userFullName }}
                                      </p>
                                    </td>
                                    <td
                                      style="padding: 10px; "
                                      :style="item.employeeType == 'Internal' ? 'background-color: #8FBC8F': 'background-color: #FFD700 '"
                                    >
                                      <p style="font-weight: bold;">
                                        {{ item.employeeType }}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </slide>
                    </carousel>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
// import ICountUp from 'vue-countup-v2';
import { Carousel, Slide } from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    storeGln: String,
  },
  data() {
    return {
      gln: null,
      chart: null,
      time2: null,
      time3: null,
      isActive: false,
    };
  },
  computed: {
    ...mapGetters(['getFiliales', 'getStoreMainboardOverview', 'getUpComingVacations', 'getRegularEmployeesOverview']),
    filteredObjects() {
      return this.getFiliales.filter((obj) => {
        return obj.storeGLN === this.storeGln;
      });
    },
  },
  mounted() {
    this.gln = this.storeGln;
    // this.stores();
  },
  methods: {
    ...mapActions(['loadFilialePagination', 'loadUpComingVacations', 'loadStoreMainboardOverview']),
    time1(value) {
      // this.$emit('time', value);
      this.loadUpComingVacations({
        storeGLN: this.storeGln,
        numberOfDays: value,
      });
    },
    modifiedDate(value) {
      return moment(value).format('DD/MM/YYYY');
    },
    toggleActive() {
      this.isActive = !this.isActive;
    },
    redirectToUser() {
      this.$router.push('/users');
    },
    redirectToTruck() {
      this.$router.push('/trucks');
    },
    redirectToRoutes() {
      this.$router.push('/routes');
    },
    redirectToTasks() {
      this.$router.push('/tasks');
    },
    redirectToVacation() {
      this.$router.push('/leave-request');
    },
  },
};
</script>

<style scoped>
.active {
  background-color: green;
}
.clicked {
  background-color: red;
  color: white;
}
.overview {
  width: 90%;
  border-radius: 10px;
  margin-top: 2%;
  margin-left: 65px;
}

.first-statics {
  height: 450px; /* Adjust the height as needed */
  width: 100%;
  /* background: linear-gradient(0deg, rgba(81, 90, 142, 0.7), rgba(81, 90, 142, 0.7)); */
  /* border: 1px solid rgb(174, 169, 169); */
  border-radius: 10px;
  padding-bottom: 20px;
  overflow: hidden; /* Hide the overflow from the table body */
}

.table-body-container {
  max-height: calc(100% - 40px); /* Adjust based on the header height and desired spacing */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.team_table {
  width: 100%;
}

.statics {
  height: 600px;
  width: 60%;
  /* background-color: gray; */
  background: linear-gradient(0deg, rgba(81, 90, 142, 0.7), rgba(81, 90, 142, 0.7));
  border-radius: 10px;
  overflow-y: auto;

  /* margin-left: 20%; */
}

.all {
  /* display: flex; */
  margin-top: 2%;
}

.vacation {
  height: 600px;
  margin-right: 10px;
  /* width: 60%; */
  /* background-color: gray; */
  background: linear-gradient(0deg, rgba(81, 90, 142, 0.7), rgba(81, 90, 142, 0.7));
  border-radius: 10px;
  /* margin-left: 20%;
  margin-bottom: 70px; */

  display: flex;
  flex-wrap: wrap; /* new */
  padding-left: 10px;
}

.row {
  display: flex; /* new */
  flex-wrap: wrap; /* new */
  width: 100%; /* new */
  text-align: left;
  padding-top: 17px;
  justify-content: center;
}

.red-background {
  background-color: red;
}

.box p {
  margin: 0;
}
.text {
  margin-top: 15px;
}

.text p{
  font-size: 20px;
}

img {
  margin-top: 20px;
}

.one {
  background-color: white;
  width: 30%;
  height: 40%;
  margin: 11px;

  border-radius: 10px;
}

@media screen and (max-width: 1200px){
  .vacation {
    height: 526px;
    width: 85%;
    margin-left: 0px;
    margin-bottom: 70px;

    display: flex;
    flex-wrap: wrap; /* new */
    padding-left: 10px;
  }

  .text p{
    font-size: 16px;
  }
}
</style>
