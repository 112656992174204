<template>
  <div class="home">
    <section
      class="main no-flex"
      style="margin-top: 13px;"
    >

      <FilialeListing
        @click="obj"
        @show-other-component="showOtherComponent = true"
        @show-the-component="showOtherComponent = false"
      />
      <!-- <UsersAttendance
        :user="user"
      /> -->
      <FilialeDetails
        v-if="!showOtherComponent"
        :store-gln="storeGLN"
        @time="emitNumber"
      />
      <!-- <OnVacationModal
        :show-modal="showLoginModal"
        @hideModal="hideM"
      /> -->
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import FilialeListing from '@/components/filiale/FilialeListing.vue'
import FilialeDetails from '@/components/FilialeDetails.vue'

import { mapGetters } from 'vuex'
// import UsersAttendance from '@/components/properties/UsersAttedance.vue'


export default {
  name: 'Home',
  components: {
    FilialeListing,
    FilialeDetails,
    // UsersAttendance,
  },
  data() {
    return {
      user: '',
      storeGLN: '',
      showOtherComponent: false,
      showLoginModal: true,
      propNumber: null,
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),

  },
  methods: {
    materialId(id) {
      this.materialIdToShow = id
    },
    handleUser(id) {
      this.user = id;
    },
    obj(value) {
      this.storeGLN = value
    },
    emitNumber(value) {
      // console.log('emitValue', value)
      this.propNumber = value
    },
    showLogin() {
      this.showLoginModal = true
    },
    hideM() {
      this.showLoginModal = false
    },
  },
}
</script>

<style scoped lang="scss">

@media screen and (max-width: 1800px){
  .main{
    padding: 90px 15px 15px 110px;
  }
}

@media screen and (max-width: 1200px){
  .main{
    padding: 90px 15px 15px 90px;
  }
}
@media screen and (max-width: 1041px) {
  .main {
    padding: 90px 0.75rem 15px 0.75rem;
  }
}
</style>
