<template>
  <div>
    <b-modal
      id="add-filiale"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('AddNewFiliale')"
      size="lg"
      hide-footer
      @shown="handleShowModal"
      @close="onCancel"
    >
      <b-form>
        <b-tabs
          v-model="tabIndex"
        >
          <b-tab
            :title="$t('FilialeInformation')"
          >
            <template #title>
              <strong style="color: #0071AE">{{ $t("FilialeInformation") }}</strong>
            </template>
            <div
              class="d-flex"
            >
              <b-form-group
                id="input-group-1"
                :label="$t('FilialeName')"
                label-for="input-1"
                style="width: 47%; margin-right: 25px; margin-top: 10px;"
              >
                <b-form-input
                  id="input-1"
                  v-model="$v.filiale.name.$model"
                  :state="validateState('name')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                :label="$t('SelectCanton')"
                label-for="input-2"
                style="width: 47%; margin-right: 25px; margin-top: 10px;"
              >
                <vue-select
                  v-model="cantons"
                  required
                  :options="getCantons"
                  label="name"
                  :value="countryStateId"
                  :placeholder="$t('Select')"
                  aria-describedby="input-1-live-feedback"
                />
                <span
                  v-show="formSubmitted && !$v.cantons.required"
                  style="color: red"
                >{{ $t('PleaseSelectCanton') }}</span>
              </b-form-group>
            </div>

            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('GlobalNumber')"
                label-for="input-1"
                style="width: 47%; margin-right: 25px; margin-top: 10px;"
              >
                <b-form-input
                  id="input-3"
                  v-model="$v.filiale.gln.$model"
                  :state="validateState('gln')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                :label="$t('Email')"
                label-for="input-1"
                style="width: 47%; margin-top: 10px;"
              >
                <b-form-input
                  id="input-4"
                  v-model="$v.filiale.email.$model"
                  :state="validateState('email')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_email_format")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <b-form-group
              id="input-group-6"
              :label="`${$t('PhoneNumber')}:`"
              label-for="input-9"
              style="width: 47%; margin-top: 10px;"
            >
              <vue-phone-number-input
                id="input-5"
                v-model="filiale.phone"
                default-country-code="CH"
                :error="isPhoneNumberValid == null ? false : isPhoneNumberValid == false ? true : false"
                :preferred-countries="['AL', 'CH', 'DE']"
                :state="validateState('phone')"
                @update="getFormattedNumber"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">{{
                $t("This_is_a_required_field_and_must_be_at_least_3_letters")
              }}</b-form-invalid-feedback>
            </b-form-group>
          </b-tab>
          <pre />
          <b-tab
            :title="$t('AddresInformations')"
          >
            <template #title>
              <strong style="color: #0071AE">{{ $t("AddressInformations") }}</strong>
            </template>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('Street')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-6"
                  v-model="$v.filiale.street.$model"
                  :state="validateState('street')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('HouseNumber')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-7"
                  v-model="$v.filiale.houseNumber.$model"
                  :state="validateState('houseNumber')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('DoorNumber')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-8"
                  v-model="$v.filiale.doorNumber.$model"
                  :state="validateState('doorNumber')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_contains_numbers")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('AddNew40')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-9"
                  v-model="$v.filiale.postalCode.$model"
                  :state="validateState('postalCode')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="d-flex">
              <b-form-group
                id="input-group-1"
                :label="$t('City')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-10"
                  v-model="$v.filiale.city.$model"
                  :state="validateState('city')"
                  aria-describedby="input-1-live-feedback"
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                :label="$t('Country')"
                label-for="input-1"
              >
                <b-form-input
                  id="input-11"
                  v-model="$v.filiale.country.$model"
                  :state="validateState('country')"
                  aria-describedby="input-1-live-feedback"
                  readonly
                />
                <b-form-invalid-feedback id="input-1-live-feedback">{{
                  $t("This_is_a_required_field_and_must_be_at_least_3_letters")
                }}</b-form-invalid-feedback>
              </b-form-group>
            </div>
          </b-tab>
          <b-tab
            :title="$t('ShippingInformation')"
          >
            <template #title>
              <strong style="color: #0071AE">{{ $t("ShippingInformation") }}</strong>
            </template>
            <div class="d-flex">
              <b-form-group
                id="input-group-2"
                :label="$t('Manager')"
                label-for="input-2"
              >
                <vue-select
                  id="input-12"
                  v-model="manager"
                  required
                  :options="getFreeManagers"
                  label="userFullName"
                  :value="userId"
                  :placeholder="$t('Select')"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
              <b-form-group
                id="input-group-2"
                :label="$t('TeamLeader')"
                label-for="input-2"
              >
                <vue-select
                  v-model="teamLeader"
                  required
                  :options="getfreeTeamLeaders"
                  label="userFullName"
                  :value="userId"
                  :placeholder="$t('Select')"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </div>
            <b-form-group
              id="input-group-2"
              :label="$t('Co-TeamLeader')"
              label-for="input-2"
            >
              <vue-select
                v-model="coTeamLeader"
                required
                :options="getfreeCoTeamLeaders"
                label="userFullName"
                :value="userId"
                :placeholder="$t('Select')"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </b-tab>
          <pre />
          <!-- <div>
            <b-button
              type="button"
              variant="danger"
              class="buttonCancel"
              style="margin-right: 15px"
              @click="onCancel"
            >
              {{ $t("Cancel") }}
            </b-button>
            <b-button
              type="button"
              variant="none"
              class="buttonSubmit"
              @click="onSubmitAddFiliale"
            >
              {{ $t("Submit") }}
            </b-button>
          </div> -->
        </b-tabs>
      </b-form>
      <div style="margin-top:15px">
        <b-button
          v-show="tabIndex ==2"
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmitAddFiliale"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          v-show="tabIndex ==0 || tabIndex ==1"
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="tabIndex++"
        >
          {{ $t("EmployeeNext") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
  // alpha,
  minValue,
  numeric,
  email,
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      countryStateId: null,
      userId: null,
      teamLeader: null,
      coTeamLeader: null,
      manager: null,
      tabIndex: 0,
      formSubmitted: false,
      isPhoneNumberValid: null,
      cantons: null,
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      phone: '',
      filiale: {
        name: '',
        gln: '',
        phone: '',
        email: '',
        street: '',
        houseNumber: '',
        doorNumber: '',
        postalCode: '',
        city: '',
        country: 'Switzerland',
        countryId: '47bcbbb6-6d82-4682-65c5-08db2c4e9562',
        countryStateId: '',
        countryState: '',
        teamLeaderId: '',
        managerId: '',
        coTeamLeaderId: '',
      },
    };
  },
  validations: {
    filiale: {
      name: {
        required,
        minLength: minLength(3),
        // alphaNum,
      },
      gln: {
        required,
        minLength: minLength(3),
        // alphaNum,
      },
      phone: {
        required,
      },
      email: {
        required,
        email,
      },
      street: {
        required,
        minLength: minLength(3),
        // alpha,
      },
      postalCode: {
        required,
        minLength: minLength(3),
        // alphaNum,
      },
      doorNumber: {
        required,
        minLength: minValue(1),
        numeric,
      },
      houseNumber: {
        required,
        minLength: minLength(1),
      },
      country: {
        minLength: minLength(3),
        // alpha,
      },
      city: {
        required,
        minLength: minLength(3),
        // alpha,
      },
      canton: {
      },
    },
    cantons: {
      required,
    },
  },
  computed: {
    ...mapGetters(['getCantons', 'getfreeTeamLeaders', 'getfreeCoTeamLeaders', 'getFreeManagers']),

  },

  watch: {
    'cantons.name': {
      handler(value) {
        this.filiale.countryState = value;
      },
      immediate: true,
    },
    'cantons.countryStateId': {
      handler(value) {
        this.filiale.countryStateId = value;
      },
      immediate: true,
    },
    'manager.userId': {
      handler(value) {
        this.filiale.managerId = value;
      },
      immediate: true,
    },
    'teamLeader.userId': {
      handler(value) {
        this.filiale.teamLeaderId = value;
      },
      immediate: true,
    },
    'coTeamLeader.userId': {
      handler(value) {
        this.filiale.coTeamLeaderId = value;
      },
      immediate: true,
    },
  },
  mounted() {
    // console.log('thissss', this.getfreeTeamLeaders);
  },
  methods: {
    ...mapActions(['loadFreeManagers']),

    handleShowModal() {
      this.loadFreeManagers()
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.filiale[name];
      return $dirty ? !$error : null;
    },
    onSubmitAddFiliale() {
      this.formSubmitted = true;
      this.$v.filiale.$touch();
      if (this.$v.filiale.$anyError) {
        return;
      }
      this.$v.cantons.$touch();
      if (this.$v.cantons.$anyError) {
        return;
      }
      this.$emit('addFiliale', this.filiale);
      this.$refs.modal.hide();
      this.onReset();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });

      // console.log('log', this.filiale);
    },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    getFormattedNumber(n) {
      if (n.isValid == true) {
        this.isPhoneNumberValid = true
        this.filiale.phone = n.nationalNumber
        this.filiale.countryCode = n.countryCallingCode
      } else {
        this.filiale.countryCode = ''
        this.isPhoneNumberValid = false
        return;
      }
      console.log()
    },
    onReset() {
      this.filiale.name = '';
      this.filiale.gln = '';
      this.filiale.phone = '';
      this.filiale.email = '';
      this.filiale.street = '';
      this.filiale.houseNumber = '';
      this.filiale.doorNumber = '';
      this.filiale.postalCode = '';
      this.filiale.countryState = null;
      this.filiale.countryStateId = null;
      this.filiale.country = 'Switzerland';
      this.filiale.city = '';
      this.filiale.phone = '';
      this.filiale.teamLeaderId = null;
      this.filiale.coTeamLeaderId = null;
      this.teamLeader = null;
      this.coTeamLeader = null;
      this.cantons = null;
      this.formSubmitted = false;
      this.tabIndex = 0;
      this.manager = null;
    },
  },
};
</script>

<style lang="scss" scoped>
// form {
//   // display: grid;
//   // grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
// .form {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }

.d-flex{
  display: flex;
}

#input-group-1, #input-group-2{
  width: 47%;
  margin-right: 25px;
  margin-top: 10px;
}
// .form2 {
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   gap: 10px;
// }
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
