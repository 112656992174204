<template>
  <div>
    <b-modal
      id="edit-teamLeader-store"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('Edit TeamLeader')"
      size="l"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-3"
          :label="`${$t('Select role')}:`"
        >
          <div class="radio-form">
            <div class="flex aic">
              <input
                id="`first`"
                v-model="radio.teamleader"
                type="radio"
                value="TeamLeader"
                name="test"
                required
              >
              <label>{{ $t("TeamLeader") }}</label>
            </div>
            <div class="flex aic">
              <input
                id="`second`"
                v-model="radio.coteamleader"
                type="radio"
                value="Co-TeamLeader"
                name="test"
                required
              >
              <label>{{ $t("Co-TeamLeader") }}</label>
            </div>
          </div>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Select free TeamLeader"
          label-for="input-2"
        >
          <vue-select
            v-for="teamleader in fullNamesByRoles"
            :key="teamleader.userId"
            v-model="teamLeader"
            required
            :options="fullNamesByRoles"
            label="userFullName"
            :value="teamleader.userId"
            placeholder="Select"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <pre />
        <div>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmitAddFiliale"
          >
            {{ $t("Submit") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  minLength,
  alpha,
  minValue,
  numeric,
  alphaNum,
  email,
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  mixins: [validationMixin],
  data() {
    return {
      teamLeader: '',
      isPhoneNumberValid: null,
      cantons: null,
      radio: {
        teamleader: '',
        coteamleader: '',
      },
      config: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
      },
      phone: '',
      roleOptions: ['CEO', 'Sewer', 'TeamLeader', 'Admin', 'Manager', 'PatternMaker', 'Trainee'],
      personalInfoForm: {
        firstName: '',
        lastName: '',
        salutation: '',
        gender: '',
        city: '',
        dateOfBirth: '',
        phoneNumber: '',
        postalCode: '',
        doorNumber: '',
        houseNumber: '',
        street: '',
        country: '',
        passport: '',
        aufenthaltsbewilligung: '',
        ahv: '',
        email: '',
        role: '',
      },
      filiale: {
        name: '',
        gln: '',
        phone: '',
        email: '',
        street: '',
        houseNumber: '',
        doorNumber: '',
        postalCode: '',
        city: '',
        country: '',
        canton: '',
      },
      workInfoForm: {
        startingDate: '',
        endingDate: '',
        workload: '',
        vacationDays: '',
        employeeType: '',
        role: '',
        salary: '',
      },
      salutation: ['Mr.', 'Ms.', 'BA.', 'BSc.', 'DI.', 'MA.', 'MSc.', 'MBA.', 'PhD.'],
    };
  },
  validations: {
    filiale: {
      name: {
        required,
        minLength: minLength(3),
        alpha,
      },
      gln: {
        required,
        minLength: minLength(3),
        alpha,
      },
      phone: {
        required,
      },
      email: {
        required,
      },
      street: {
        required,
        minLength: minLength(3),
        alpha,
      },
      postalCode: {
        required,
        minLength: minLength(3),
        alphaNum,
      },
      doorNumber: {
        required,
        minLength: minValue(1),
        numeric,
      },
      houseNumber: {
        required,
        minLength: minLength(3),
      },
      country: {
        required,
        minLength: minLength(3),
        alpha,
      },
      city: {
        required,
        minLength: minLength(3),
        alpha,
      },
      canton: {
        required,
        email,
      },
    },
  },
  computed: {
    ...mapGetters(['getCantons', 'getFullNamesById', 'fullNamesByRoles']),

    // getSal() {
    //   return this.$store.state.login.salutation;
    // },
  },

  watch: {
    'cantons.countryStateId': {
      handler(value) {
        this.filiale.canton = value;
      },
      immediate: true,
    },
    'radio.teamleader': {
      handler(value) {
        console.log(value);
      },
    },
  },
  mounted() {
    // console.log('thissss', this.fullNamesByRoles);
    // console.log('radio', this.radio);
  },
  methods: {
    ...mapActions(['loadUsersFullNamesById']),
    validateState(name) {
      const { $dirty, $error } = this.$v.filiale[name];
      return $dirty ? !$error : null;
    },
    onSubmitAddFiliale() {
      // this.$v.filiale.$touch();
      // if (this.$v.filiale.$anyError) {
      //   return;
      // }
      this.$emit('addFiliale', this.filiale);
      this.$refs.modal.hide();
      // this.onReset();
      // setTimeout(() => {
      //   this.$v.$reset();
      // }, 0);
      // this.$nextTick(() => {
      //   this.$v.$reset();
      // });

      // console.log('log', this.filiale);
    },
    // async getFullNamesById() {
    //   const res = await this.loadUsersFullNamesById({
    //     role: this.radio.teamleader,
    //   });

    // },
    onCancel() {
      this.$refs.modal.hide();
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.onReset();
    },
    onReset() {
      // Reset our form values
      this.personalInfoForm.firstName = '';
      this.personalInfoForm.lastName = '';
      this.personalInfoForm.salutation = '';
      this.personalInfoForm.gender = '';
      this.personalInfoForm.city = '';
      this.personalInfoForm.street = '';
      this.personalInfoForm.dateOfBirth = '';
      this.personalInfoForm.postalCode = '';
      this.personalInfoForm.doorNumber = '';
      this.personalInfoForm.country = '';
      this.personalInfoForm.phoneNumber = '';
      this.personalInfoForm.email = '';
      this.personalInfoForm.role = '';
      // Trick to reset/clear native browser form validation state
      this.teamLeader = '';
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.form2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.flex-form {
  display: flex;
  flex-direction: column;
}
.uploadButtons {
  display: flex;
  align-items: flex-end;
}
</style>
